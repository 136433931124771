define('m09/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {

      var self = this;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var item = bauteile.findBy('id', name);

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;

      switch (typ) {
        case 'breite':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -2 * hoeheMasskette - 2 * abstandVonDerMasslinie;
            y = 0;
            z = 0;
          } else {
            x = 0;
            y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - 2 * hoeheMasskette - abstandVomBauteil + abstandVonDerMasslinie;
            z = 0;
          }
          break;
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = item.get('boxsizes').objectAt(0).get('y') / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20) - 2 * hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
          } else {
            x = -2 * hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
            y = item.get('boxsizes').objectAt(0).get('y') / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'resthoeheoben':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')) / 10 + Number(item.get('durchbr').objectAt(0).get('d')) / 10 + Number(item.get('durchbr').objectAt(0).get('resthoeheoben')) / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20) - hoeheMasskette - abstandVonDerMasslinie;;
          } else {
            x = -hoeheMasskette - abstandVonDerMasslinie;
            y = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')) / 10 + Number(item.get('durchbr').objectAt(0).get('d')) / 10 + Number(item.get('durchbr').objectAt(0).get('resthoeheoben')) / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'resthoeheunten':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')) / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20) - hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = -hoeheMasskette - abstandVonDerMasslinie;
            y = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')) / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'durchbruchhoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')) / 10 + Number(item.get('durchbr').objectAt(0).get('d')) / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20) - hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = -hoeheMasskette - abstandVonDerMasslinie;
            y = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')) / 10 + Number(item.get('durchbr').objectAt(0).get('d')) / 20;
            z = -(item.get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'auflagerabstand':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10 - item.get('durchbr').objectAt(0).get('abstandauflager') / 20;
            y = 0;
            z = item.get('boxsizes').objectAt(0).get('z') / 20 + hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10 - item.get('durchbr').objectAt(0).get('abstandauflager') / 20;
            y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - hoeheMasskette + abstandVonDerMasslinie;
            z = item.get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'durchbruchlaenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + item.get('durchbr').objectAt(0).get('durchbruchlaenge') / 20;
            y = 0;
            z = item.get('boxsizes').objectAt(0).get('z') / 20 + hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + item.get('durchbr').objectAt(0).get('durchbruchlaenge') / 20;
            y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - hoeheMasskette + abstandVonDerMasslinie;
            z = item.get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'vorholzlaenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 20;
            y = 0;
            z = item.get('boxsizes').objectAt(0).get('z') / 20 + abstandVomBauteil + 2 * hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 20;
            y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - 2 * hoeheMasskette + abstandVonDerMasslinie;
            z = item.get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'x':
          x = Number(item.get('boxsizes').objectAt(0).get('x')) - 0.5;
          y = 0;
          z = 0;
          break;
        case 'y':
          x = 0.5;
          y = Number(item.get('boxsizes').objectAt(0).get('y')) - 0.5;
          z = 0;
          break;
        case 'z':
          x = 0;
          y = 0;
          z = Number(item.get('boxsizes').objectAt(0).get('z')) - 0.5;
          break;
      }

      if (x3d.get('ergebnisGeladen') === true) {

        // console.log(name);
        // console.log(item);

        if (name.substring(0, 8) === 'schraube' || item.get('typ') === 'verbindungsmittel') {

          if (this.get('typ') === 'xPos') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge')) / 10 - (Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10) - Number(item.get('translations').objectAt(0).get('x')) / 10) / 2;
              y = 0;
              z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 20) + 2 * abstandVomBauteil + 3 * hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge')) / 10 - (Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10) - Number(item.get('translations').objectAt(0).get('x')) / 10) / 2;
              y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - 2 * abstandVomBauteil - 3 * hoeheMasskette + abstandVonDerMasslinie;
              z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 20);
            }
          }

          if (this.get('typ') === 'zzz') {

            var value = self.getVorgaenger(x3d, bauteile, item);

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -hoeheMasskette - abstandVonDerMasslinie;
              y = 0;
              z = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 40 + Math.abs(Number(item.get('translations').objectAt(0).get('z')) / 20);
            } else {
              x = Number(item.get('translations').objectAt(0).get('x')) / 10;
              y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - hoeheMasskette + abstandVonDerMasslinie;
              z = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 40 + Math.abs(Number(item.get('translations').objectAt(0).get('z')) / 20);
            }
            // console.log('zMinSchraube');
            // console.log('x: '+x+'; y: '+y+'; z: '+z);
          }

          if (this.get('typ') === 'zMin') {

            var value = self.getVorgaenger(x3d, bauteile, item);

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -hoeheMasskette - abstandVonDerMasslinie;
              y = 0;
              z = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20 - Math.abs(Number(item.get('translations').objectAt(0).get('z')) / 20);
            } else {
              x = Number(item.get('translations').objectAt(0).get('x')) / 10;
              y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - hoeheMasskette + abstandVonDerMasslinie;
              z = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20 - Math.abs(Number(item.get('translations').objectAt(0).get('z')) / 20);
            }
            // console.log('zMinSchraube');
            // console.log('x: '+x+'; y: '+y+'; z: '+z);
          }

          if (this.get('typ') === 'zPos') {

            var value = self.getVorgaenger(x3d, bauteile, item);

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -hoeheMasskette - abstandVonDerMasslinie;
              y = 0;
              z = 0;
            } else {
              x = Number(item.get('translations').objectAt(0).get('x')) / 10;
              y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - hoeheMasskette + abstandVonDerMasslinie;;
              z = 0;
            }
          }

          if (this.get('typ') === 'zMax') {

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -hoeheMasskette - abstandVonDerMasslinie;;
              y = 0;
              z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20 + Math.abs(Number(item.get('translations').objectAt(0).get('z')) / 20);
            } else {
              x = Number(item.get('translations').objectAt(0).get('x')) / 10;
              y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - hoeheMasskette + abstandVonDerMasslinie;;
              z = +Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20 + Math.abs(Number(item.get('translations').objectAt(0).get('z')) / 20);
            }
            // console.log('zMaxSchraube');
            // console.log('x: '+x+'; y: '+y+'; z: '+z);
          }
        }
      }

      if (name === 'lastenPfeil1') {
        var xVerschiebung = 2;
        x = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10);
        y = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheunten') / 10) - 0.6;
      }

      if (name === 'lastenPfeil3') {
        x = 1;
        y = -0.4;
      }

      cords = x + ' ' + y + ' ' + z;

      // console.log('Text pos: '+cords);

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.viewpointHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;
      var item = bauteile.findBy('id', name);

      switch (typ) {
        case 'breite':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = -1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'resthoeheoben':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'resthoeheunten':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'durchbruchhoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'auflagerabstand':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'durchbruchlaenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'vorholzlaenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'x':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'y':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'z':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
      }

      if (x3d.get('ergebnisGeladen') === true) {

        if (name.substring(0, 8) === 'schraube' || item.get('typ') === 'verbindungsmittel') {

          if (this.get('typ') === 'xPos') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 0;
              omega = -w90Grad;
            } else {
              x = 0;
              y = 0;
              z = 0;
              omega = 0;
            }
          }

          if (this.get('typ') === 'zMin' || this.get('typ') === 'zPos' || this.get('typ') === 'zMax' || this.get('typ') === 'zzz') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          }
        }
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      // console.log('Text rotation: '+values);

      if (name.substring(0, 11) === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var self = this;
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (x3d.get('htbreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (x3d.get('hthoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          break;
        case 'resthoeheoben':
          if (x3d.get('resthoeheobenEingetragen')) {
            value = Number(item.get('durchbr').objectAt(0).get('resthoeheoben')).toFixed(1);
          }
          break;
        case 'resthoeheunten':
          if (x3d.get('resthoeheuntenEingetragen')) {
            value = Number(item.get('durchbr').objectAt(0).get('resthoeheunten')).toFixed(1);
          }
          break;
        case 'durchbruchhoehe':
          if (x3d.get('resthoeheuntenEingetragen') && x3d.get('resthoeheobenEingetragen') && x3d.get('hthoeheEingetragen')) {
            value = Number(item.get('durchbr').objectAt(0).get('d')).toFixed(1);
          }
          break;
        case 'auflagerabstand':
          if (x3d.get('abstandauflagerEingetragen')) {
            value = Number(item.get('durchbr').objectAt(0).get('abstandauflager')).toFixed(1);
          }
          break;
        case 'vorholzlaenge':
          if (x3d.get('vorholzlaengeEingetragen')) {
            value = Number(item.get('durchbr').objectAt(0).get('vorholzlaenge')).toFixed(1);
          }
          break;

        case 'durchbruchlaenge':
          if (x3d.get('durchbruchlaengeEingetragen')) {
            value = Number(item.get('durchbr').objectAt(0).get('durchbruchlaenge')).toFixed(1);
          }
          break;
        case 'x':
          value = 'x';
          break;
        case 'y':
          value = 'y';
          break;
        case 'z':
          value = 'z';
          break;
      }

      if (x3d.get('ergebnisGeladen') === true) {

        if (item.get('typ') === 'verbindungsmittel' && x3d.get('ergebnisGeladen')) {

          if (typ === 'zzz') {
            value = (Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 2 - Math.abs(Number(item.get('translations').objectAt(0).get('z')))).toFixed(1);
          }

          if (typ === 'xPos') {
            value = (Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge')) - Number(item.get('translations').objectAt(0).get('x'))).toFixed(1);
          }

          if (typ === 'zMin' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
            value = (Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 2 - Math.abs(Number(item.get('translations').objectAt(0).get('z')))).toFixed(1);
          }

          if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {

            value = self.getVorgaenger(x3d, bauteile, item);
            value = value.toFixed(1);

            var schraubenAnzahl = 0;
            bauteile.forEach(function (bauteil) {
              // console.log(bauteil.id);
              // console.log(bauteil.get('typ') +':::'+ x3d.get('aktuellerSchraubenTyp') );
              if (bauteil.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
                schraubenAnzahl++;
              }
            });
            value = schraubenAnzahl / 2 - 1 + ' x ' + value;
          }

          if (typ === 'zMax' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
            value = (Math.abs(Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 2) - Math.abs(Number(item.get('translations').objectAt(0).get('z')))).toFixed(1);
          }
        }
      }

      if (name === 'lastenPfeil1' && x3d.get('querkraftEingetragen')) {
        value = x3d.get('querkraft');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      if (name === 'lastenPfeil3' && x3d.get('momentlastEingetragen')) {
        value = x3d.get('momentlast');
        value = parseFloat(value).toFixed(1) + ' kNm';
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.schraubenlisteAktualisiert'),

    textgroesse: (function () {
      var skalierungsfaktor = Number(this.get('model').objectAt(0).get('skalierungsfaktor'));
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'traeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'traeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('resthoeheobenAktiv') === true && name === 'traeger' && typ === 'resthoeheoben') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('resthoeheuntenAktiv') === true && name === 'traeger' && typ === 'resthoeheunten') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abstandauflagerAktiv') === true && name === 'traeger' && typ === 'auflagerabstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vorholzlaengeAktiv') === true && name === 'traeger' && typ === 'vorholzlaenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('durchbruchlaengeAktiv') === true && name === 'traeger' && typ === 'durchbruchlaenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('querkraftAktiv') === true && name === 'lastenPfeil1') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('momentlastAktiv') === true && name === 'lastenPfeil3') {
        emsvcolor = '1 0 0';
      }

      if (name === 'koordinatensystem') {
        emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.resthoeheobenAktiv', 'model.firstObject.resthoeheuntenAktiv', 'model.firstObject.durchbruchlaengeAktiv', 'model.firstObject.vorholzlaengeAktiv', 'model.firstObject.abstandauflagerAktiv', 'model.firstObject.querkraftAktiv', 'model.firstObject.momentlastAktiv'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = bauteile.findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    },

    getVorgaenger: function getVorgaenger(x3d, bauteile, item) {

      var zPos = Number(item.get('translations').objectAt(0).get('z'));
      var vorgaenger = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 2;

      bauteile.forEach(function (bauteil) {
        if (bauteil.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          if (Number(item.get('translations').objectAt(0).get('z')) > Number(bauteil.get('translations').objectAt(0).get('z'))) vorgaenger = Number(bauteil.get('translations').objectAt(0).get('z'));
        }
      });

      var value = Math.abs(vorgaenger - Number(item.get('translations').objectAt(0).get('z')));

      return value;
    }

  });

  exports['default'] = x3dText;

});